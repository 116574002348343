import { createSelector } from 'reselect';
import { amaSelectors } from './ama.selectors';
import {
  AssignmentManagementStore,
  ReduxPage,
  ReduxPageWithCatalog,
  ReduxPageWithPrimaryTaxonomies
} from './ama.models';

const {
  getAmaStore,
} = amaSelectors;

const makeGetReduxPage = <T extends ReduxPage>(reduxPage: T) => (globalState): AssignmentManagementStore[T] => getAmaStore(globalState)[reduxPage];

export const makeGetCatalogInReduxPage = (reduxPage: ReduxPageWithCatalog) => {
  return createSelector(
    [makeGetReduxPage(reduxPage)],
    (page) => {
      return page.catalog;
    }
  );
};

export const makeGetPrimaryTaxonomiesInReduxPage = (reduxPage: ReduxPageWithPrimaryTaxonomies) => {
  return createSelector(
    [makeGetReduxPage(reduxPage)],
    (page) => {
      return page.primaryTaxonomies;
    }
  );
};
