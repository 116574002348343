import React, {
  Suspense,
  Component
} from 'react';
import {
  Redirect,
  Router,
  Switch
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Provider,
  connect,
  ConnectedProps
} from 'react-redux';
import { createHashHistory } from 'history';
import { compose } from 'recompose';
import { ConnectedRouter } from 'connected-react-router';
import $ from 'jquery';
import {
  ELSSecurityRoutes,
  ELSIdleProvider,
  ELSTokenServiceRegistrar,
  ELSTokenHelper,
  ELSCommonUIConstants,
  ELSPageLoader,
  ELSRouterHelper,
  ELSAccessibilityFocusState,
} from '@els/els-ui-common-react';
import { ELSModalProvider } from '@els/els-component-modal-react';
import {
  injectIntl,
  IntlProvider,
  IntlShape
} from 'react-intl';
import {
  registerMouseFlowService,
  setDefaultAppConfig
} from './app.config';
import { amaActions } from '../../redux/ama/ama.actions';
import { configureStore } from '../../redux/redux.store';
import { locationActions } from '../../redux/location/location.actions';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import ErrorBoundary from '../error-boundary/ErrorBoundary.component';
import LocationChangeHandler from '../location-change-handler/LocationChangeHandler.component';
import { AppRoutes } from './app.routes';
import {
  AppConstants,
  RoutePath
} from './app.constants';
import '../../assets/main.scss';
import {
  addSearchParams,
  getLanguage,
  messages
} from '../../utilities/app.utilities';
import ErrorNotification from '../error-notification/ErrorNotification.component';
import { translateMessages } from '../../translations/message.utilities';
import { StaticMessagesKeys } from '../../translations/message.constants';
import { ELSLoggingService } from '../els.components';
import { pickCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import { amaSelectors } from '../../redux/ama/ama.selectors';
import { ELSTokenUser } from '../../models/els.dtos';
import AnalyticsRegistrar from '../analytics-registrar/AnalyticsRegistrar.component';

setDefaultAppConfig();
const history = createHashHistory<{}>();
const store = configureStore(history);

const fileName = 'App.component';

const mapDispatchToProps = {
  setUser: amaActions.setUser,
  setMessages: amaActions.setMessages,
  redirect: locationActions.redirect,
  fetchCourseSection: amaActions.fetchCourseSection,
  fetchAllAppFeatureFlags: amaActions.fetchAllAppFeatureFlags,
  setAppLinkCookies: amaActions.setAppLinkCookies,
};

const mapStateToProps = (state) => ({
  // NOTE: Avoid passing any redux state into App component other than this one
  // Normally, it's OK to re-render App component. However, ELSRouterHelper.createRoute calls a HOC inside its render function
  // and this is a bad practice as it forces component inside Route re-mount everytime App component re-render.
  // isMaintenance is made to return TRUE/FALSE so:
  // isMaintenance changes from FALSE to TRUE: Redirect from other page to Maintenance page, other pages won't be rendered
  // isMaintenance changes from TRUE to FALSE: Redirect from Maintenance page to Course Plan, Course Plan is rendered once
  isMaintenance: amaSelectors.getIsMaintenance(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type AppProps = PropsFromRedux & {
  intl: IntlShape;
};

type AppState = {
  cookieCheckComplete: boolean;
}

const HTMLHeadSEOComponent = withHTMLHeadSEO(null)(null);

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    window.$ = $;
    window.jQuery = $;
    ELSTokenServiceRegistrar.initializeFromReload();
    this.state = {
      cookieCheckComplete: false
    };
  }

  componentDidMount() {
    const {
      intl,
      setMessages,
      setAppLinkCookies,
      fetchAllAppFeatureFlags,
    } = this.props;
    const tokenUser: ELSTokenUser = ELSTokenHelper.getUser();
    const translatedMessages = translateMessages(StaticMessagesKeys, intl);
    setMessages(translatedMessages);
    const cookies = pickCookies();
    if (cookies.linkId && cookies.token) {
      setAppLinkCookies(cookies);
    }
    this.setState({ cookieCheckComplete: true });
    registerMouseFlowService();
    ELSTokenServiceRegistrar.registerTokenTimeoutCallback(() => {
      this.props.redirect(RoutePath.TIMEOUT);
    });
    if (!isEmpty(tokenUser)) {
      fetchAllAppFeatureFlags();
    }
  }

  initializeAppByAdminBackdoor = (userParams) => {
    this.props.redirect(addSearchParams(RoutePath.LOGIN, {
      courseId: userParams.courseId.toString(),
      token: ELSTokenHelper.getToken()
    }));
  };

  render() {
    if (!this.state.cookieCheckComplete) {
      return null;
    }
    const { isMaintenance } = this.props;

    return (
      <ConnectedRouter history={history}>
        <ELSModalProvider>
          <>
            <LocationChangeHandler />
            <ErrorBoundary>
              <HTMLHeadSEOComponent />
              <AnalyticsRegistrar />
              <Router history={history}>
                <Suspense fallback={<ELSPageLoader />}>
                  <Switch>
                    {ELSSecurityRoutes.getSecurityRoutes(this.initializeAppByAdminBackdoor)
                      .map(route => {
                        if (!isMaintenance && route.path === `/${ELSCommonUIConstants.security.States.Maintenance}`) {
                          return null;
                        }
                        return ELSRouterHelper.createRoute(route);
                      })}
                    {isMaintenance && <Redirect to={`/${ELSCommonUIConstants.security.States.Maintenance}`} />}

                    {AppRoutes.getRoutes()
                      .map((route) => ELSRouterHelper.createRoute(route))}
                    {!isMaintenance && <Redirect from={`/${ELSCommonUIConstants.security.States.Maintenance}`} to={RoutePath.COURSE} />}
                    <Redirect from="/" exact to={`/${ELSCommonUIConstants.security.States.Admin}`} />
                    <Redirect to={`/${ELSCommonUIConstants.security.States.PageNotFound}`} />
                  </Switch>
                </Suspense>
              </Router>
              <ELSAccessibilityFocusState />
              <ELSIdleProvider
                timeout={AppConstants.IDLE_TIMEOUT}
                onSessionTimeout={() => {
                  ELSLoggingService.info(fileName, 'handle session timeout');
                  this.props.redirect(RoutePath.TIMEOUT);
                }}
              />
              <ErrorNotification />
            </ErrorBoundary>
          </>
        </ELSModalProvider>
      </ConnectedRouter>
    );
  }
}

const enhancers = [
  connector,
  injectIntl,
];

const ConnectedApp = compose(...enhancers)(App);

const AppWithStoreAndIntProvider = () => (
  <Provider store={store}>
    <IntlProvider locale={getLanguage()} messages={messages[getLanguage()]}>
      <ConnectedApp />
    </IntlProvider>
  </Provider>
);

export default AppWithStoreAndIntProvider;
