import { AssignmentManagementStore } from './ama/ama.models';

export const PERSIST_KEY = 'globalAppState';

export const reduxStateDomains = {
  ROUTER_STATE: 'router', // TODO: See if we can safely change this name
  AMA_STATE: 'amaState',
};

export interface RootState {
  router: History;
  amaState: AssignmentManagementStore;
}

export const restoreStateKeys: Record<keyof AssignmentManagementStore, boolean> = {
  userHistory: true,
  userHistoryStateCompletedRequests: true,
  courseSectionId: true,
  currentCourse: true,
  errors: true,
  evolveProducts: true,
  isbns: true,
  isCourseOwner: true,
  linkData: true,
  messages: true,
  pendingRequestCount: false,
  roleId: true,
  userCourseOwnerRecords: true,
  userId: true,
  users: true,
  catalog: true,
  eaqUsers: true,
  eaqIsbns: true,
  masteryResetSuccess: true,
  primaryTaxonomies: true,
  taxonomies: true,
  assignment: true,
  assignments: false,
  assessmentSubmissionsMap: true,
  checkedSyllabusItemIds: true,
  syllabusFolderInfo: true,
  courseBuilderState: true,
  externalEntities: true,
  isBatchEditModeEnabled: true,
  isDragDropModeEnabled: true,
  batchEditSelectedSyllabusItems: true,
  batchEditUpdatedSyllabusItems: false,
  // Don't turn this on in the future because we want to re-fetch syllabus items on page refresh
  syllabusItems: false,
  interactiveReviewCatalogItem: true,
  crosswalkUsers: false,
  registeredToken: true,
  // Items below here were added on the assumption they were missed by accident
  evolveUser: true,
  featureFlagsGrouped: true,
  eolsUser: true,
  assessmentStartTimeMap: true,
  sherpathLessonEditorPage: false,
  simulationEditorPage: false,
  skillEditorPage: false,
  interactiveReviewEditorPage: false,
  interactiveReviewEditorPreview: false,
  eaqEditorPage: false,
  evolveResourcesEditorPage: false,
  shadowHealthEditorPage: false,
  ebookEditorPage: false,
  appLinkCookies: true,
  collapsedFolderIds: true,
  skillSubmissionRecords: false,
  skillStaticData: false,
  userEngagementReport: true,
  moduleSequenceMap: true,
  assessments: true,
  enableDeepLink: true,
  sherpathPowerpointEditorPage: true,
  sherpathGroupActivityEditorPage: true,
  sherpathCaseStudyEditorPage: true,
  osmosisVideoEditorPage: true,
  groupActivity: true,
  hasRunAuthessHealthCheck: true,
  osmosisTokenDto: true,
  migratedEntitlements: true,
  abTestFlavors: true,
  importSelection: true
};

// Anything we do not plan to restore later can be skipped on storage in the first place
export const omitStateKeys: Partial<Record<keyof AssignmentManagementStore, boolean>> = Object.keys(restoreStateKeys).reduce((acc, key) => {
  if (restoreStateKeys[key]) {
    return acc;
  }
  return {
    ...acc,
    [key]: true
  };
}, {});
