import moment from 'moment';
import { AdobeAnalyticsPageData } from '../redux/ama/ama.models';

export const getSemester = () => {
  const day = moment().dayOfYear();
  const fallStart = moment('2000-08-01').dayOfYear();
  const summerStart = moment('2000-06-01').dayOfYear();

  if (day >= fallStart) {
    return 'fall';
  }
  if (day >= summerStart) {
    return 'summer';
  }
  return 'spring';
};

export const getEmptyPageData = (): AdobeAnalyticsPageData => {
  return {
    visitor: {
      accessType: '',
      loginStatus: '',
      loginSuccess: '',
      loginFailure: '',
      accountId: '',
      accountName: '',
      ipAddress: '',
      userId: '',
      departmentId: '',
      departmentName: '',
      usagePathInfo: '',
    },
    page: {
      businessUnit: '',
      environment: '',
      productName: '',
      language: '',
      name: '',
      type: '',
      testId: '',
      channel: '',
      loadTime: 0,
      loadTimestamp: '',
      analyticsPagename: '',
      sectionName: '',
      lastTrackedPage: '',
      cmsName: '',
      noTracking: '',
      eventName: '',
    },
    education: {
      courseId: '',
      courseName: '',
      isbn: '',
      assignmentId: '',
      assignmentName: '',
      assignmentType: '',
      moduleId: '',
      moduleName: '',
      moduleType: '',
      studentId: '',
      instructorId: '',
      semester: getSemester(),
      beforeAfterDueDate: '', // date compared to assignment due date in the format "due date +||- Int"
      assignmentNumericGrade: '',
      assignmentDuration: '',
      assignmentScore: '',
    }
  };
};

export const getBeforeAfterDueDateDue = (dueDate: string): string => {

  if (!dueDate) {
    return '';
  }

  const dateFormat = 'YYYY-MM-DD';
  const dateString = moment(dueDate).format(dateFormat);

  const diff = moment(new Date()).diff(dueDate);
  const days = moment.duration(diff).days();

  if (diff < 0 && days === 0) {
    return dateString;
  }

  return `${dateString} ${days > 0 ? `+${days}` : days}`;
};
